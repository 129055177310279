import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/proscom/hse-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { ComponentLinks, FigmaEmbed } from '../common';
import { useState, useMemo } from 'react';
import { Playground, Props } from 'docz';
import { RangeDatePicker } from '@hse-design/react';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "rangedatepicker"
    }}>{`RangeDatePicker`}</h1>
    <ComponentLinks storybook={'/?path=/story/calendar-calendar--range-date-picker'} figma={'https://www.figma.com/file/cBHD57QcCt9WDT4e7e2B3w/hse_general_library?node-id=9288%3A6457'} mdxType="ComponentLinks" />
    <h2 {...{
      "id": "описание"
    }}>{`Описание`}</h2>
    <p>{`RangeDatePicker – компонент для выбора диапазона дат в календаре. Компонент представляет собой два связанных инпута. Значения в инпутах выделяются в календаре как диапазон.`}</p>
    <Playground __position={1} __code={'() => {\n  const fromProps = useMemo(() => ({ label: \'Дата начала\' }), [])\n  const toProps = useMemo(() => ({ label: \'Дата окончания\' }), [])\n  const [value, setValue] = useState([\'08.07.2022\', \'09.07.2022\'])\n  return (\n    <div\n      style={{\n        width: \'100%\',\n        height: 150,\n        display: \'flex\',\n        justifyContent: \'center\',\n        paddingTop: \'30px\',\n      }}\n    >\n      <RangeDatePicker\n        fromProps={fromProps}\n        toProps={toProps}\n        yearFrom={1920}\n        yearTo={new Date().getFullYear()}\n        value={value}\n        onChange={setValue}\n      />\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      ComponentLinks,
      FigmaEmbed,
      useState,
      useMemo,
      Playground,
      Props,
      RangeDatePicker,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
      {() => {
        const fromProps = useMemo(() => ({
          label: 'Дата начала'
        }), []);
        const toProps = useMemo(() => ({
          label: 'Дата окончания'
        }), []);
        const [value, setValue] = useState(['08.07.2022', '09.07.2022']);
        return <div style={{
          width: '100%',
          height: 150,
          display: 'flex',
          justifyContent: 'center',
          paddingTop: '30px'
        }}>
          <RangeDatePicker fromProps={fromProps} toProps={toProps} yearFrom={1920} yearTo={new Date().getFullYear()} value={value} onChange={setValue} mdxType="RangeDatePicker" />
        </div>;
      }}
    </Playground>
    <h2 {...{
      "id": "поведение"
    }}>{`Поведение`}</h2>
    <FigmaEmbed node='?node-id=9288%3A5708' height={1200} mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "анатомия"
    }}>{`Анатомия`}</h2>
    <FigmaEmbed node='?node-id=9288%3A5630' height={1400} mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "использование"
    }}>{`Использование`}</h2>
    <FigmaEmbed node='?node-id=9288%3A5736' mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "пропы"
    }}>{`Пропы`}</h2>
    <Props of={RangeDatePicker} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      